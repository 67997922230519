import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, LinkBox, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Accueil | Bienvenue sur le site de Net Masters Tennis Hub
			</title>
			<meta name={"description"} content={"Entrez dans le royaume de Net Masters, un sanctuaire pour les passionnés de tennis et les champions en herbe."} />
			<meta property={"og:title"} content={"Accueil | Bienvenue sur le site de Net Masters Tennis Hub"} />
			<meta property={"og:description"} content={"Entrez dans le royaume de Net Masters, un sanctuaire pour les passionnés de tennis et les champions en herbe."} />
			<meta property={"og:image"} content={"https://alpineadventuresfr.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://alpineadventuresfr.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://alpineadventuresfr.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://alpineadventuresfr.com/images/main.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="75%"
					md-width="100%"
					margin="0px 0px 200px 0px"
				>
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Bienvenue au centre de tennis Net Masters
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Masters Tennis Hub
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					En savoir plus
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Bienvenue au centre de tennis Net Masters
				</Text>
				<Text font="--lead" color="--darkL2" text-align="center" md-width="100%">
					Entrez dans le royaume de Net Masters, un sanctuaire pour les passionnés de tennis et les champions en herbe. Notre centre ne se contente pas de jouer au tennis - il s'agit de le vivre, de le respirer et d'évoluer à chaque match. Avec un engagement d'excellence et une passion pour le sport, Net Masters est votre destination privilégiée pour l'entraînement de tennis, la camaraderie et une croissance inégalée.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://alpineadventuresfr.com/images/1.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Une équipe d'entraîneurs d'élite
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Apprenez des meilleurs grâce à notre équipe d'entraîneurs expérimentés qui apportent des stratégies et des idées personnalisées à votre jeu.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Des installations modernes
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Jouez sur des courts de première qualité conçus pour des performances optimales, que ce soit pour l'entraînement, la compétition ou un match amical.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						L'esprit de communauté
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Rejoignez une communauté dynamique d'amoureux du tennis. Net Masters est plus qu'un club - c'est une famille qui soutient et encourage chacun de ses membres.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Programmes d'entraînement personnalisés
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Des séances d'entraînement individuelles aux stages de groupe, nous proposons une variété de programmes adaptés à votre style, à vos objectifs et à votre emploi du temps.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="150px 0 150px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="40%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://alpineadventuresfr.com/images/2.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="60%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Découvrez l'avantage Net Masters
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
						Net Masters Tennis Hub offre un mélange unique d'entraînement professionnel, de jeu compétitif et d'une atmosphère de soutien communautaire. Que vous preniez une raquette pour la première fois ou que vous soyez un joueur expérimenté affinant ses compétences, nos programmes sont conçus pour élever votre jeu vers de nouveaux sommets.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});